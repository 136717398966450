import React from 'react';
import { Layout } from '../../../components/Layout';

const CondominiumServices = () => (
  <Layout
    title="Condominium Plans and Development Surveys in Calgary and Area | Axiom Geomatics Experts"
    description="Need help with condominium plans in Calgary? Axiom Geomatics offers legal survey services for accurate development and building plans."
    keywords="municipal survey, construction survey, condominium unit, condo unit ownership, condo boundaries, common property, what are unit factors, strata plan, condo own buy sell, condo bylaws, restrictions, creating condos, building condos, registering condo, land titles condos"
  >
    <main>
      <h1>Condominium Services Calgary & Area</h1>
      <p>
        Development of a condominium complex is a significant undertaking usually involving a lot of
        construction surveying at the beginning. Survey services required include the{' '}
        <strong>initial municipal construction survey</strong>, determination of condo unit
        boundaries, determination of common property, creation of the condominium plan, municipal
        approval(s) and registration of land titles. Making changes to an existing development also
        has its own unique challenges.
      </p>

      <p>
        Keeping in mind that condominiums are a type of ownership, there are two sub-categories to
        this ownership:
      </p>

      <dl>
        <dt>Bare Land Unit</dt>
        <dd>
          This is effectively ownership of a piece of land, referred to as a "unit" and you still
          belong to a condominium. This is a common type of ownership for townhouses. However
          single-family homes can also be built on this making it appear no different than a
          subdivision.
        </dd>
        <dt>Traditional or Building Unit</dt>
        <dd>
          This is what most people think of when they think of a condo unit and is the type of unit
          you would find in a multi-story complex or high-rise.
        </dd>
      </dl>

      <p>
        Condominiums are commonly developed in phases. There are multiple techniques for
        accomplishing this, however all of them are highly complex.{' '}
        <a href="tel:+14034727777">Give us a call</a> to discuss the best solution for your project.
      </p>

      <p>It is quite common to take a 30+ year-old building and convert it to a condominium.</p>
    </main>
  </Layout>
);

export default CondominiumServices;
